import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

const Newsletter = props => {
  
  const [state, setState] = useState({ email: null })

  const _handleChange = e => {
    setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  const _handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(state.email)
      .then(data => {
        if (data.result !== "success") {
          throw data.msg
        }
        alert(data.msg)
      })
      .catch(err => {
        alert(err)
      })
  }

  return (
    <form onSubmit={_handleSubmit}>
      <input
        type="email"
        onChange={_handleChange}
        placeholder="La tua Email"
        name="email"
      />
      {props.children}
    </form>
  )
}

export default Newsletter
